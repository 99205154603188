
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { AxiosPromise } from 'axios';
import { Promised } from 'vue-promised';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { getLogicalBotEditHistory } from '@/features/om27/api/edit-bot.api';
import { RouteNames } from '@/constants';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { EditLogicalBotHistory, SimpleAutoSignOffBot } from '@/features/om27/types/edit-bot.types';
import EditBotHistoryGrid from '@/features/om27/components/EditBotHistoryGrid.vue';
import { RpaOpsModule } from '@/features/om27/store/om27.module';

@Component({
  components: {
    FullScreenForm,
    Promised,
    Grid,
    GridCell,
    EditBotHistoryGrid,
  },
})
export default class EditBotHistory extends Vue {
  get closeRoute(): RawLocation {
    return { name: RouteNames.RpaOpsEditBot };
  }

  get logicalBotId(): number {
    return Number(this.$route.params.logicalBotId);
  }

  get simpleAutoSignOffLinkedBots(): SimpleAutoSignOffBot[] {
    return RpaOpsModule.editLogicalBotHistoryData.linkedBots || [];
  }

  promise: AxiosPromise<EditLogicalBotHistory[]> | null = null;
  async created() {
    console.log(this.logicalBotId);
    this.promise = getLogicalBotEditHistory(this.logicalBotId);
  }
}
