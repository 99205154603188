
import { Component, Prop, Vue } from 'vue-property-decorator';
import TablePagination from '@/components/table/TablePagination.vue';
import {
  EditLogicalBotHistory,
  EditLogicalBotHistoryRecord,
  SimpleAutoSignOffBot,
} from '@/features/om27/types/edit-bot.types';
import { BotColumn } from '@/features/om27/types/logdata.types';
import GButton from '@/components/gsk-components/GskButton.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';

@Component({
  components: {
    TablePagination,
    GButton,
    GDialog,
  },
})
export default class EditBotHistoryGrid extends Vue {
  @Prop({ required: true, type: Array }) historyData!: EditLogicalBotHistory[];
  @Prop({ required: true, type: Array }) linkedBots!: SimpleAutoSignOffBot[];

  page = 1;
  perPage = 25;
  viewingEvidenceTemplate: string | null = null;

  get rows(): EditLogicalBotHistoryRecord[] {
    const start = (this.page - 1) * this.perPage;
    const end = start + this.perPage;
    return this.historyData
      .map((row) => row.logicalBot)
      .sort((row) => (new Date(row.updateNotes.modificationDate).getTime() > new Date(row.updateNotes.modificationDate).getTime()) ? -1 : 1)
      .slice(start, end);
  }

  get columns(): BotColumn<EditLogicalBotHistoryRecord>[] {
    return [{
      key: 'modifiedUser',
      field: 'modifiedUser',
      title: 'Modified User',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.updateNotes.lastChangedHumanUser);
      },
    }, {
      key: 'updateNotes',
      field: 'updateNotes',
      title: 'Update Notes',
      align: 'left',
      width: 300,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.updateNotes.updateNotes);
      },
    }, {
      key: 'historyUpdateDate',
      field: 'historyUpdateDate',
      title: 'Update Date',
      align: 'left',
      width: 200,
    }, {
      key: 'botName',
      field: 'botName',
      title: 'Bot Name',
      align: 'left',
      width: 200,
    }, {
      key: 'botDisabled',
      field: 'botDisabled',
      title: 'Bot Disabled',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(String(row.botDisabled));
      },
    }, {
      key: 'botDesignFolder',
      field: 'botDesignFolder',
      title: 'Design/Folder Name',
      align: 'left',
      width: 300,
    }, {
      key: 'botAdditionalInfo',
      field: 'botAdditionalInfo',
      title: 'Additional Info',
      align: 'left',
      width: 300,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.botAdditionalInfo);
      },
    }, {
      key: 'displayOrder',
      field: 'displayOrder',
      title: 'Order',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(String(row.displayOrder));
      },
    }, {
      key: 'scheduleDescription',
      field: 'scheduleDescription',
      title: 'Schedule',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.scheduleDescription);
      },
    }, {
      key: 'lenientAutoSignoffFlag',
      field: 'lenientAutoSignoffFlag',
      title: 'Lenient Auto Sign Off',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(String(row.lenientAutoSignoffFlag));
      },
    }, {
      key: 'lenientApprover',
      field: 'lenientApprover',
      title: 'Lenient Approver and Notes',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.lenientApprover);
      },
    }, {
      key: 'simpleAutoSignoffBotId',
      field: 'simpleAutoSignoffBotId',
      title: 'Simple Auto Sign Off Bot',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        if (!row.simpleAutoSignoffBotId) {
          return <span>—</span>;
        }
        return <span>{row.simpleAutoSignoffBotId}</span>;
      },
    }, {
      key: 'scheduleGroupId',
      field: 'scheduleGroupId',
      title: 'Auto Sign Off Schedule',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(String(row.scheduleGroupId));
      },
    }, {
      key: 'schedTimeZone',
      field: 'schedTimeZone',
      title: 'Bot Time Zone',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.schedTimeZone);
      },
    }, {
      key: 'schedStartTime',
      field: 'schedStartTime',
      title: 'Bot Start Time (HH:MM)',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.schedStartTime);
      },
    }, {
      key: 'schedEndTime',
      field: 'schedEndTime',
      title: 'Bot End Time (HH:MM)',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.schedEndTime);
      },
    }, {
      key: 'assigneeChanges',
      field: 'assigneeChanges',
      title: 'Assignee Changes',
      align: 'left',
      width: 300,
      renderBodyCell: ({ row }, h) => {
        const { assigneeChanges } = row;
        const values = Object.values(assigneeChanges)
          .map(({ humanUser, humanUserMudId }) => (<div>{`${humanUser} (${humanUserMudId})`}</div>));
        if (values.length === 0) {
          return <span>—</span>;
        }
        return (<ul>{ values.map((val) => (<li style="text-align: left">{val}</li>)) }</ul>);
      },
    }, {
      key: 'botTriggerType',
      field: 'botTriggerType',
      title: 'Trigger Type',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.botTriggerType);
      },
    }, {
      key: 'assignmentGroup',
      field: 'assignmentGroup',
      title: 'Assignment Group',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.assignmentGroup);
      },
    }, {
      key: 'supportTeam',
      field: 'supportTeam',
      title: 'Support Team',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.supportTeam);
      },
    }, {
      key: 'soxValue',
      field: 'soxValue',
      title: 'SOX Value',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.soxValue);
      },
    }, {
      key: 'soxReason',
      field: 'soxReason',
      title: 'SOX Reason',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.soxReason);
      },
    }, {
      key: 'soxDocumentReference',
      field: 'soxDocumentReference',
      title: 'SOX Document Reference',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.soxDocumentReference);
      },
    }, {
      key: 'soxDescription',
      field: 'soxDescription',
      title: 'SOX Description',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.soxDescription);
      },
    }, {
      key: 'soxHumanApprover',
      field: 'soxHumanApprover',
      title: 'SOX Approver',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return this.renderTextColumnData(row.soxHumanApprover);
      },
    }, {
      key: 'categoryChanges',
      field: 'categoryChanges',
      title: 'Category Changes',
      align: 'left',
      width: 300,
      renderBodyCell: ({ row }, h) => {
        const { categoryChanges } = row;
        const values = Object.values(categoryChanges)
          .map((category) => (<div>{Object.values(category).join(',')}</div>));
        if (values.length === 0) {
          return <span>—</span>;
        }
        return (<ul>{ values.map((val) => (<li style="text-align: left">{val}</li>)) }</ul>);
      },
    }, {
      key: 'evidenceTemplate',
      field: 'evidenceTemplate',
      title: 'Evidence Template',
      align: 'left',
      width: 200,
      renderBodyCell: ({ row }, h) => {
        return (
          <button onClick={this.showEvidenceTemplateExtended.bind(this, row.evidenceTemplate)}>Click to view</button>
        );
      },
    }];
  }

  showEvidenceTemplateExtended(evidenceTemplate: string) {
    this.viewingEvidenceTemplate = evidenceTemplate;
  }

  onEvidenceTemplateDialogHidden() {
    this.viewingEvidenceTemplate = null;
  }

  renderTextColumnData(text?: string) {
    if (text !== '' && !text || text === 'null') {
      return <span>—</span>;
    }
    return <span>{text}</span>
  }
}
